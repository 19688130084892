<template>
  <TTView>
    <VRow>
      <VCol>
        <VAlert
          colored-border
          border="left"
          type="info"
          elevation="1"
        >
          Правила импорта файлов [<!-- eslint-disable-next-line vue/max-len max-len --><a
            href="https://burning-heart.atlassian.net/wiki/spaces/TT/pages/903479307/xlsx#%D0%98%D0%BC%D0%BF%D0%BE%D1%80%D1%82-%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81%D0%BE%D0%B2"
            target="_blank"
          >Confluence</a>]
        </VAlert>
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <VAlert
          colored-border
          border="left"
          type="warning"
          elevation="1"
        >
          <ul>
            <li
              v-for="(warning, index) in warnings"
              :key="index"
            >
              {{ warning.title }}
            </li>
          </ul>
        </VAlert>
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <TestQuestionsFileUploadForm
          :loading="loading"
          :file="file"
          @update:file="file = $event"
          @submit="handleSubmit"
          @cancel="handleCancel"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TestQuestionsFileUploadForm from '../../../components/talent/test/TestQuestionsFileUploadForm.vue';

export default {
  name: 'TestQuestionsFileUpload',

  components: {
    TestQuestionsFileUploadForm,
  },

  data() {
    return {
      loading: false,
      file: null,
      warnings: [
        { title: 'формат: zip, xlsx' },
        { title: 'архив состоит из файла с вопросами в формате xlsx и изображений' },
        { title: 'изображения с допустимыми форматами: jpg jpeg png' },
        { title: 'Не использовать кириллицу в названиях файла/изображений/архива' },
        { title: 'Не использовать вложенные папки в архиве' },
      ],
    };
  },

  computed: {
    testId() {
      return this.$route.params.testId;
    },
  },

  methods: {
    async handleSubmit() {
      try {
        this.loading = true;

        const {
          file,
          testId,
        } = this;

        const data = new FormData();
        data.append('file', file);
        await this.$di.api.Talent.uploadFile({ testId, data });

        this.$di.notify.snackSuccess('Файл успешно загружен');
        this.file = null;
      } catch (err) {
        this.$di.notify.snackError('Ошибка загрузки. Файл не соответствует требуемому формату');
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.file = null;
      this.$router.go(-1);
    },
  },
};
</script>
