<template>
  <VCard
    :loading="loading"
    :disabled="loading"
  >
    <VCardTitle>
      Загрузка из файла
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VFileInput
                :value="file"
                accept=".zip,.xlsx"
                label="Файл"
                @change="$emit('update:file', $event)"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        :loading="loading"
        :disabled="!filled"
        @click="handleSubmit"
      >
        Загрузить
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
export default {
  name: 'TestQuestionsFileUploadForm',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    file: {
      type: [String, File],
      default: null,
    },
  },

  computed: {
    filled() {
      return this.file;
    },
  },

  methods: {
    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit');
    },
  },
};
</script>
